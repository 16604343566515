import React from 'react';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class APartingOfTheWays extends React.Component {
    render() {
        return (
            <Layout>
                <h1>A Parting of the Ways - Martial Arts Magazine circa 1989</h1>
                <StaticImage src="../../images/apartingoftheway/2913558.jpg" alt="A parting of the ways"/>
            </Layout>);
    }
}

export default APartingOfTheWays;
